// extracted by mini-css-extract-plugin
export var button = "sing-in-page-module--button--c25de";
export var ccs = "sing-in-page-module--ccs--8a159";
export var contactDetailsIcon = "sing-in-page-module--contactDetailsIcon--79a48";
export var contactForm = "sing-in-page-module--contactForm--291bb";
export var contactFormInputs = "sing-in-page-module--contactFormInputs--4c181";
export var display = "sing-in-page-module--display--a692d";
export var email = "sing-in-page-module--email--798cf";
export var footer = "sing-in-page-module--footer--cacb4";
export var footerContent = "sing-in-page-module--footerContent--389ec";
export var footerL = "sing-in-page-module--footerL--811c8";
export var footerLinks = "sing-in-page-module--footerLinks--5a323";
export var footerLogo = "sing-in-page-module--footerLogo--e5e81";
export var footerR = "sing-in-page-module--footerR--f6f2d";
export var formTitle = "sing-in-page-module--formTitle--a888b";
export var gcsSymbolIcon = "sing-in-page-module--gcsSymbolIcon--63028";
export var globalCareSupply = "sing-in-page-module--globalCareSupply--20c51";
export var ifYouAreNotRegisteredGo = "sing-in-page-module--ifYouAreNotRegisteredGo--8cad9";
export var input = "sing-in-page-module--input--13236";
export var inputFieldWIthLabel = "sing-in-page-module--inputFieldWIthLabel--4bd57";
export var inputLabel = "sing-in-page-module--inputLabel--75ea2";
export var inputLabel2 = "sing-in-page-module--inputLabel2--e34a4";
export var inputRow = "sing-in-page-module--inputRow--49c29";
export var logo = "sing-in-page-module--logo--a6961";
export var patternsIcon = "sing-in-page-module--patternsIcon--cabd1";
export var privacyPolicy = "sing-in-page-module--privacyPolicy--6f79f";
export var registerContent = "sing-in-page-module--registerContent--54a18";
export var registerPage = "sing-in-page-module--registerPage--a7d00";
export var registerSection = "sing-in-page-module--registerSection--4e407";
export var shippingPolicy = "sing-in-page-module--shippingPolicy--bae5c";
export var signInPage = "sing-in-page-module--signInPage--9690a";
export var visaIcon = "sing-in-page-module--visaIcon--5bea4";