import * as React from "react";
import * as styles from "./sing-in-page.module.css";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Button, Icon, Input, InputGroup, InputRightElement, Text, useToast } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { CheckIcon } from "@chakra-ui/icons";
import { GET_USER } from "../useAuth";
import { Link } from "gatsby";

const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: {
      login: $login
      password: $password
    }) {
      status
    }
  }
`;

const LoginForm: FunctionComponent = () => {
  const toast = useToast()

    // Password toggle handler
  const [passwordShown, setPasswordShown] = useState(false);
  function togglePassword() {
    setPasswordShown(!passwordShown);
  }

     const [logIn, { loading, error }] = useMutation(LOG_IN, {
     refetchQueries: [{
      query: GET_USER
    }]
     });
  
    const errorMessage = error?.message || '';
  const isEmailValid =
    !errorMessage.includes('empty_email') &&
    !errorMessage.includes('empty_username') &&
    !errorMessage.includes('invalid_email') &&
    !errorMessage.includes('invalid_username');
  const isPasswordValid =
    !errorMessage.includes('empty_password') &&
    !errorMessage.includes('incorrect_password');
  
   function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    
    event.preventDefault();
    const data:any = new FormData(event.currentTarget);
    
     const values = Object.fromEntries(data);
     
     const email = values.login
     const password = values.password

    logIn({
      variables: {
        login: email,
        password
      },
    }).catch(error => {

      if (errorMessage.includes('pending_approval')) {
        toast({
          status: "error",
          description: 'Your email is pending approval. Please try again later.'
        })      }
      console.error(error);
    });
  }
  return (
    <div className={styles.signInPage}>
      <div className={styles.registerSection}>
        <div className={styles.registerContent}>
          <img className={styles.patternsIcon} alt="" src="/patterns.svg" />
          <div className={styles.contactForm}>
            <form method="post" onSubmit={handleSubmit}>
            <div className={styles.contactFormInputs}>
              <div className={styles.formTitle}>
                <div className={styles.display}>Sign In</div>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.inputFieldWIthLabel}>
                  <div className={styles.inputLabel}>
                    <div className={styles.email}>Email</div>
                  </div>
                  <Input
                    id="log-in-email" type="email" name="login" autoComplete="username" required
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#b6d07c"
                    placeholder="johndoe@email.com"
                    />
                    {!isEmailValid ? (
                <Text color="red" className="error-message">Invalid email. Please try again.</Text>
              ) : null}
                </div>
              </div>
              <div className={styles.inputRow} >
                <div className={styles.inputFieldWIthLabel}>
                  <div className={styles.inputLabel}>
                    <div className={styles.email}>Password</div>
                  </div>
                  <InputGroup>
                  <Input
                    id="log-in-password" name="password" autoComplete="current-password" required 
                    className={styles.input}
                    variant="outline"
                    textColor="#050e15"
                    borderColor="#ebf3fb"
                    focusBorderColor="#b6d07c"
                    type={passwordShown ? "text" : "password"}
                  />
                   <InputRightElement>
                     <Button onClick={togglePassword} background="transparent" color="gray.700" _hover={{ bg: 'transparent', border: "none" }} _focus={{ border: "none"}} >{passwordShown ? <Icon as={AiFillEyeInvisible} ></Icon> : <Icon as={AiFillEye}></Icon>}</Button>
                      </InputRightElement></InputGroup>
                        {!isPasswordValid ? (
                    <Text color="red" className="error-message">Invalid password. Please try again.</Text>
                  ) : null}
                  <div className={styles.inputLabel2}>
                    <div className={styles.ifYouAreNotRegisteredGo}>
                      <span>{`If you are not registered, go to `}</span>
                      <b className={styles.registerPage}><a href="/register">Register page</a></b>
                      <span>
                        {" "}
                        and follow the prompts to create your account. Once you
                        have registered, you can sign in using your
                        newly-created username and password.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className={styles.button}
                variant="solid"
                colorScheme="gcsblue"
                type="submit"
              >
                Sign in
              </Button>
              </div>
              {/* {errorMessage.includes('pending_approval') ? <Text color="red" className="error-message">Your email is pending approval. Please try again later.</Text>
 :null} */}
            </form>
          </div>
        </div>
        <img
          className={styles.contactDetailsIcon}
          alt=""
          src="/contactdetails1@2x.png"
        />
      </div>
      
    </div>
  );
};

export default LoginForm
