import * as React from "react";

import Layout from "../components/Base/Layout";
import LogInForm from "../components/Login/LoginForm";
import { SEO } from "../components/Base/Seo";
import UnAuthContent from "../components/Login/UnAuthContent";

const LogIn = () => {
  return (
    <Layout>
      <UnAuthContent>
        <LogInForm />
      </UnAuthContent>
    </Layout>
  );
}

export default LogIn

export const Head = () => (
  <SEO title="Global Care Supply | Login " description="Login page." />
)