import { Box, Button, Container, HStack, Heading, Link, Text } from "@chakra-ui/react"

import { IconShieldCheck } from '@tabler/icons';
import React from "react"

const Loading: React.FC = () => {

    return (
    <Box bg="bg-surface" py="28" position="relative" minHeight="100vh">
        <Container maxW="800px" px="4" py={{ base: '4', md: '8' }} >
          
            <Box display="flex" justifyContent="center" bg="white" py="14" px='2' textAlign="center" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);" >
            
                <Box w={{ base: '100%', md: "480px" }}>
                    <Heading size="xl" color="gray.800" > Loading </Heading>
                    <Text lineHeight="7" py='4' color="gray.600"> Please wait while your page is loading... </Text>
                </Box>
            </Box>
        </Container>
    </Box>
    )
}

export default Loading